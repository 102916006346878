<template>
  <gt-layout>
    <template slot="logo">
      <logo />
    </template>
    <template slot="setting">
      <router-link to="/">
        <el-dropdown-item>其他</el-dropdown-item>
      </router-link>
    </template>
    <template slot="navbar-dropdown">
      <span />
      <router-link to="/">
        <el-dropdown-item>首页</el-dropdown-item>
      </router-link>
    </template>
  </gt-layout>
</template>
<script>
import Layout from "../framework/layout";
import { EventBus } from "../framework/utils";
import { AppEventTypeEnum } from "../framework/enums";
import logo from "./components/logo";

export default {
  components: {
    GtLayout: Layout,
    logo,
  },
  data() {
    return {};
  },
  mounted() {
    setInterval(() => {
      this.checkUserPremission();
    }, 30 * 1000);
  },
  methods: {
    checkUserPremission() {
      this.$api.User.getUserPremissions().then((res) => {
        const {
          data: { feature_codes },
        } = res;
        const userInfo = JSON.parse(localStorage.getItem("app_user_info"));
        const { permission } = userInfo;
        const newCodes = feature_codes.join(",");
        const oldCodes = permission.join(",");
        if (newCodes !== oldCodes) {
          window.alert("权限发生变更，确认后将重新登录。");
          EventBus.$emit(AppEventTypeEnum.LOGIN_OUT);
        }
      });
    },
  },
};
</script>
<style lang="less" scoped></style>
