import { render, staticRenderFns } from "./App.vue?vue&type=template&id=efad1c84&scoped=true&"
import script from "./App.vue?vue&type=script&lang=js&"
export * from "./App.vue?vue&type=script&lang=js&"
import style0 from "./App.vue?vue&type=style&index=0&id=efad1c84&prod&lang=less&scoped=true&"
import style1 from "./App.vue?vue&type=style&index=1&id=efad1c84&prod&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "efad1c84",
  null
  
)

export default component.exports