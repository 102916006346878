import request from "@/utils/request";

export default {
  /**
   * @description 用户列表查询
   * @param {*} params
   * @returns
   */
  getUserList: (params) => request.get("/ucenter/getulist", { params }),

  /**
   * @description 用户列表查询
   * @param {*} params
   * @returns
   */
  getBanLogList: (params) => request.get("/ucenter/banuserlog", { params }),

  /**
   * @description 用户操作
   * @param {*} params
   * @returns
   */
  operateOnUser: (params) => request.post("/ucenter/banuser", { ...params }),

  /**
   * @description 编辑用户
   * @param {*} params
   * @returns
   */
  editOnUser: (params) => request.post("/ucenter/edituser", { ...params }),
};
