import request from "@/utils/request";

export default {
  /*
   * 获取道具列表
   * */
  getList: (params) => request.get("/haiyaa/props", { params }),

  /*
   * 道具详情
   * */
  detail: (id) => request.get(`/haiyaa/props/${id}`),

  /*
   * 更新道具信息
   * */
  update: (data) => request.post("/haiyaa/props", data),

  /*
   * 启用道具
   * */
  online: (id) => request.put(`/haiyaa/props/${id}/online`),

  /*
   * 停用道具
   * */
  offline: (id) => request.put(`/haiyaa/props/${id}/offline`),

  /*
   * 预估道具成本
   * */
  rate: (data) => request.post("/haiyaa/props/rate", data),

  /**
   * @description 下载导入模板
   * @returns
   */
  downloadTemplate: () =>
    request.get("/haiyaa/props/cdk_list_download_template"),

  /**
   * @description 预览生成的 cdklist
   */
  getUploadCDKList: (params) =>
    request.get("/haiyaa/props/preview_cdk_list", { params }),

  /**
   * @description 根据道具ID生成uploadList
   */
  getUploadCdkListByPropsId: (params) => {
    const { id, ...res } = params;
    return request.get(`/haiyaa/props/cdk_list/${id}`, { params: res });
  },
};
