import Layout from "@/layout";

export default [
  {
    path: "/family",
    component: Layout,
    redirect: "/family/leader",
    name: "family",
    meta: {
      title: "家族管理",
      icon: "people",
      permissions: ["starcret/audit"],
      position: 13,
    },
    children: [
      {
        path: "leader",
        component: () => import("@/views/family/leader"),
        name: "familyLeader",
        meta: {
          title: "家族长管理",
          noCache: false,
          permissions: ["starcret/audit"],
          isParent: true,
        },
      },
      {
        path: "member",
        component: () => import("@/views/family/member"),
        name: "familyMember",
        meta: {
          title: "家族成员列表",
          noCache: false,
          permissions: ["starcret/audit"],
          isParent: true,
        },
      },
      {
        path: "reward",
        component: () => import("@/views/family/reward"),
        name: "familyReward",
        meta: {
          title: "家族长奖励管理",
          noCache: false,
          permissions: ["starcret/audit"],
          isParent: true,
        },
      },
    ],
  },
];
