import request from "@/utils/request";

export default {
  /**
   * @description 获取贵族订单列表
   * @param {*} params
   * @returns
   */
  getAuditList: (params) => request.get("/audit/heystar_apply", { params }),

  /**
   * @description 操作审核记
   * @param {*} params
   * @returns
   */
  operateRecords: (params) => request.post("/audit/operate", { ...params }),
};
