import Layout from "@/layout";

export default [
  {
    path: "/appuser",
    component: Layout,
    redirect: "/appuser/user",
    name: "appuser",
    meta: {
      title: "用户信息",
      icon: "user",
      permissions: ["star_manage"],
      position: 14,
    },
    children: [
      {
        path: "user",
        component: () => import("@/views/appuser/user"),
        name: "appuserUser",
        meta: {
          title: "信息查询",
          permissions: ["star_manage"],
        },
      },
      {
        path: "accsecurity",
        component: () => import("@/views/appuser/accsecurity"),
        name: "appuserAccsecurity",
        meta: {
          title: "账号安全管理",
          permissions: ["star_manage"],
        },
      },
    ],
  },
];
