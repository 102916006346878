import request from "@/utils/request";

export default {
  /**
   * @description 获取主播流水记录
   * @param {*} params
   * @returns
   */
  getPayList: (params) => request.get("/pay/getpaylist", { params }),
};
