import request from "@/utils/request";

export default {
  getList: (data) => request.get("/haiyaa/sending", { params: data }),

  update: (data) => request.post("/haiyaa/sending", data),

  detail: (id) => request.get(`/haiyaa/sending/${id}`),

  delete: (id) => request.delete(`/haiyaa/sending/${id}`),

  approval: (id, code) =>
    request.put(`/haiyaa/sending/approval/${id}?code=${code}`), // 审核通过

  reject: (id) => request.put(`/haiyaa/sending/reject/${id}`), // 审核驳回

  code: (params) => {
    const idsStr = params.join(",");
    return request.get(`/haiyaa/sending/audit/code/${idsStr}`, { params }); // 审核短信验证码
  },

  /**
   * @description 获取小助手消息模板
   * @returns
   */
  getMessageExplain: () => request.get("/haiyaa/sending/assistant/template"),
};
