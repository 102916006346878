import Vue from "vue";
import Cookies from "js-cookie";
import "normalize.css/normalize.css"; // 初始化默认样式
import Element from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "@/styles/index.scss"; // 全局样式
import gtui from "./framework/ui";
import "./icons";
import "./permission";
import { EventBus } from "./framework/utils";
import { AppEventTypeEnum } from "./framework/enums";
import api from "@/api/index";
import store from "./store";
import App from "./App";
import router from "./router";
import * as filters from "./filters";
import directives from "./directives";
import "./framework/auth";

Vue.use(gtui);
Vue.use(api);
Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key]);
});
Vue.use(directives);

Vue.config.productionTip = false;
Vue.use(Element, {
  size: Cookies.get("size") || "mini",
});

Vue.mixin({
  computed: {
    Enums() {
      // TODO:
      console.log("-- main Enums --", this.$store.state.dict.enums);
      return this.$store.state.dict.enums;
    },
  },
  methods: {
    getEnum() {
      //
    },
  },
});

EventBus.$on(AppEventTypeEnum.LOGIN_OUT, () => {
  store.dispatch("user/logout");
});

new Vue({
  router,
  store,
  created() {
    this.$store.dispatch("dict/getDict");
  },
  render: (h) => h(App),
}).$mount("#app");

window.Vue = Vue;
