import request from "@/utils/request";

export default {
  /**
   *  获取公告列表
   */
  getNoticeCongurationList(data) {
    return request.get("/announcement/notice", { params: data });
  },
  /**
   *  查看公告详情
   */
  getNoticeCongurationdetail(id) {
    return request.get(`/announcement/notice/${id}`);
  },

  /**
   *  保存
   */
  updateNoticeCongurationInfo(data) {
    return request.post("/announcement/notice", data);
  },
  /**
   *  公告置顶
   */
  stick(id) {
    return request.post(`/announcement/placement/${id}`);
  },
  /**
   *  删除公告
   */
  delNotice(id) {
    return request.delete(`/announcement/notice/${id}`);
  },
  /**
   *  公告上下架
   */
  publishNotice(data) {
    return request.post("/announcement/publish", data);
  },
};
