import fetch from "@/utils/fetch";

// 获取一级分类列表
export function getCategoryList(data) {
  return fetch({
    url: "/Admin/Interact/topic",
    method: "get",
    params: data,
  });
}

// 修改/新增一级分类
export function saveCategory(data) {
  return fetch({
    url: "/Admin/Interact/saveClass",
    method: "post",
    data,
  });
}

// 显示/隐藏一级分类
export function setCategoryShow(data) {
  return fetch({
    url: "/Admin/Interact/setClassShow",
    method: "post",
    data,
  });
}

// 判断是否有二级话题
export function hasTopic(data) {
  return fetch({
    url: "/Admin/Interact/hasSub",
    method: "post",
    data,
  });
}

// 获取一级分类列表
export function getCateList(data) {
  return fetch({
    url: "/Admin/Interact/getClassList",
    method: "get",
    params: data,
  });
}

// 获取话题列表
export function getTopicList(data) {
  return fetch({
    url: "/Admin/Interact/subTopic",
    method: "post",
    data,
  });
}

// 修改/新增话题
export function saveTopic(data) {
  return fetch({
    url: "/Admin/Interact/saveTopic",
    method: "post",
    data,
  });
}

// 显示/隐藏话题
export function setTopicShow(data) {
  return fetch({
    url: "/Admin/Interact/setTopicShow",
    method: "post",
    data,
  });
}
