import Layout from "@/layout";

export default [
  {
    path: "/Monitoring",
    component: Layout,
    redirect: "/Monitoring/Verify",
    name: "App监控",
    icon: "xinrenzhinan",
    noDropdown: false,
    meta: {
      title: "App监控",
      icon: "case",
      permissions: ["star_manage"],
      position: 16,
    },
    children: [
      {
        path: "Verify",
        component: () => import("@/views/userimage/Verify"),
        name: "图片审核",
        meta: {
          title: "图片审核",
          permissions: ["star_manage"],
        },
      },
      {
        path: "signature",
        component: () => import("@/views/userimage/signature"),
        name: "签名审核",
        meta: {
          title: "签名审核",
          permissions: ["star_manage"],
        },
      },
      {
        path: "Room",
        component: () => import("@/views/userimage/Room"),
        name: "房间管理",
        meta: {
          title: "房间管理",
          permissions: ["star_manage"],
        },
      },
      {
        path: "HotRoom",
        component: () => import("@/views/userimage/HotRoom"),
        name: "房间热度管理",
        meta: {
          title: "房间热度管理",
          permissions: ["star_manage"],
        },
      },
      {
        path: "music",
        component: () => import("@/views/userimage/music"),
        name: "曲库管理",
        meta: {
          title: "曲库管理",
          permissions: ["star_manage"],
        },
      },
      {
        path: "voice",
        component: () => import("@/views/userimage/voice"),
        name: "语音监控",
        meta: {
          title: "语音监控",
          permissions: ["star_manage"],
        },
      },
      {
        path: "message",
        component: () => import("@/views/userimage/message"),
        name: "消息监控",
        meta: {
          title: "消息监控",
          permissions: ["star_manage"],
        },
      },
      {
        path: "modellog",
        component: () => import("@/views/userimage/modellog"),
        name: "后台操作记录",
        meta: {
          title: "后台操作记录",
          permissions: ["star_manage"],
        },
      },
    ],
  },
];
