import NProgress from "nprogress";
import { Browser } from "./framework/utils";
import { APP_USER_INFO } from "./framework/config/consts";
import { Settings } from "@/config";
import store from "./store";
import router, { getAppRoutes } from "./router";
/* 页面进度条 * */
import "nprogress/nprogress.css";

const { toLogin } = Browser;

const { title: appTitle, loginUrl } = Settings;

NProgress.configure({ showSpinner: false });

router.beforeEach(async (to, from, next) => {
  NProgress.start();

  // 设置页面标题, tagView标题
  let { title } = to.meta;
  const { noNeedToken } = to.meta;
  if (to.meta.getTitle) {
    title = to.meta.getTitle(to);
  }

  to.meta.title = title;
  if (title) {
    document.title = `${appTitle} - ${title}`;
  } else {
    document.title = appTitle;
  }

  if (noNeedToken) {
    next();
    NProgress.done();
    return;
  }

  const cachedUser = JSON.parse(window.localStorage.getItem(APP_USER_INFO));

  if (cachedUser) {
    if (to.path === "/login") {
      next({ path: "/" });
      NProgress.done();
    } else {
      try {
        const noNeedInitRouteByCachedUser =
          store.getters.userInfo && store.getters.userInfo.id;
        if (noNeedInitRouteByCachedUser) {
          next();
        } else {
          await store.dispatch("user/setUserInfo", cachedUser);
          // 根据权限集设置路由
          const appRoutes = getAppRoutes();
          const addRoutes = await store.dispatch("user/setRoutes", appRoutes);
          router.addRoutes(addRoutes);
          next({ ...to, replace: true });
        }
      } catch (e) {
        console.log(e);
      }
    }
  } else {
    // next();
    toLogin(loginUrl, router);
    NProgress.done();
  }
});

router.afterEach(() => {
  NProgress.done();
});
