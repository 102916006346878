/**
 * @description 基础配置
 */
export default {
  title: "HAIYAA运营平台",
  /**
   * @description 开发联调环境
   *
   */

  loginUrl: "/login",

  defaultUrl: "/",

  development: {
    showHelper: true,
    // requestUrl: 'http://192.168.97.224:8687/hop/api/v1' // 联调环境
    requestUrl: "/hop/api/v1",
    //halaRequestUrl: 'https://opapi.owlchat.io'
    requestUrl: "http://47.101.133.67:8687/hop/api/v1",
  },

  /**
   * @description 测试环境/内网环境
   *
   */
  st: {
    halaRequestUrl: "https://opapi.owlchat.io", //HK-SVR1
    // requestUrl: 'http://192.168.97.224:8687/hop/api/v1'
    requestUrl: "http://47.101.133.67:8687/hop/api/v1",
  },

  /**
   * @description 前端开发需要
   *
   */
  local: {
    // requestUrl: 'http://192.168.97.224:8687' // 联调开发环境
    // requestUrl: `http://${window.location.hostname}:8687`
    //requestUrl: 'https://opapi.owlchat.io'
    requestUrl: "http://47.101.133.67:8687",
  },

  /**
   * @description 生产环境
   *
   */
  // /hop/api/v1/common/settings
  production: {
    //requestUrl: 'https://opapi.owlchat.io/hop/api/v1',
    halaRequestUrl: "https://opapi.owlchat.io",
    // requestUrl: 'http://192.168.97.224:8687'
    requestUrl: "http://47.101.133.67:8687/hop/api/v1",
  },

  /**
   * @description 辅助配置
   * */
  assistConfig: {},
};

export const abc = {};
