import request from "@/utils/request";

export default {
  /**
   *  获取预言批次列表
   */
  getBatchList(data) {
    return request.post("/predict/batch/list", data);
  },
  /**
   *  创建批次
   */
  addBatchInfo(data) {
    return request.post("/predict/batch", data);
  },
  /**
   *  修改批次信息
   */
  updateBatchInfo(id, data) {
    return request.put(`/predict/batch/${id}`, data);
  },
  /**
   *  是否显示预言批次
   */
  isShowBatchInfo(id, data) {
    return request.put(`/predict/batch/disable/${id}`, data);
  },
  /**
   * 预言批次详情
   */
  getBatchInfo(id) {
    return request.get(`/predict/batch/${id}`);
  },
  /**
   *  查询预言基础配置
   */
  getBasicInfo() {
    return request.get("/predict");
  },
  /**
   *  修改预言基础配置
   */
  updateBasicInfo(data) {
    return request.post("/predict", data);
  },
  /**
   *  获取预言题目列表
   */
  getTopicList(data) {
    return request.post("/predict/topic/list", data);
  },
  /**
   *  创建预言题目
   */
  addTopicInfo(data) {
    return request.post("/predict/topic", data);
  },
  /**
   *  预言题目详情
   */
  getTopicInfo(id) {
    return request.get(`/predict/topic/${id}`);
  },
  /**
   *  修改预言题目
   */
  updateTopicInfo(id, data) {
    return request.put(`/predict/topic/${id}`, data);
  },
  /**
   *  公开题目答案
   */
  openTopicInfo(id, data) {
    return request.put(`/predict/topic/announce/${id}`, data);
  },
  /**
   *  删除题目
   */
  delTopicInfo(id) {
    return request.delete(`/predict/topic/${id}`);
  },
};
