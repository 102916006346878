import Layout from "@/layout";

export default [
  {
    path: "/star",
    component: Layout,
    redirect: "/star/index",
    name: "star",
    meta: {
      title: "主播流水",
      icon: "star",
      noCache: false,
      permissions: ["star_manage"],
      position: 8,
    },
    children: [
      {
        path: "index",
        component: () => import("@/views/star/index"),
        name: "starOrder",
        meta: {
          title: "主播流水",
          noCache: false,
          permissions: ["star_manage"],
          isParent: true,
        },
      },
    ],
  },
];
