import Layout from "@/layout";

export default [
  {
    path: "/noticeConfiguration",
    component: Layout,
    redirect: "/noticeConfiguration/index",
    name: "noticeConfiguration",
    meta: {
      title: "公告配置",
      icon: "form",
      permissions: ["noticeConfiguration/index"],
    },
    children: [
      {
        path: "index",
        component: () =>
          import(
            /* webpackChunkName: 'activity' */ "@/views/noticeConfiguration/index"
          ),
        name: "noticeConfiguration",
        meta: { title: "公告配置", permissions: ["noticeConfiguration/index"] },
      },
    ],
  },
];
