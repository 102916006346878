import { Obj } from "../framework/utils";
import request from "@/utils/request";

/**
 * @description 表单生成
 */
export default {
  ...Obj.generateCURD("/form", request),

  /**
   * @description 复制
   * @param {*} data
   * @returns
   */
  copy(id) {
    return request.post("/form/duplicate", { id });
  },
};
