import request from "@/utils/request";

export default {
  /**
   * @description 打款审核列表
   * @param {*} params
   * @returns
   */
  getRemitList(data) {
    return request.get("/finance/settle/list", { params: data });
  },

  /**
   *  获取验证码
   */
  getcode(data) {
    return request.post("/finance/settle/verifycode", data);
  },

  /**
   *  提现审核
   */
  approvalSettle(data) {
    return request.post("/finance/settle/approval", data);
  },

  /**
   *  结算查看
   */
  readSettle(data) {
    return request.post("/finance/settle/read", data);
  },
  /**
   *  云账户信息列表
   */
  getAccountList() {
    return request.get("/finance/account/list");
  },

  /**
   *  云账户主信息
   */
  getAccountDetail(id) {
    return request.get(`/finance/account/detail/${id}`);
  },
  /**
   *  云账户汇总数据
   */
  getAccountSummary(id, begin, end) {
    return request.get(
      `/finance/account/summary/${id}?begin=${begin}&end=${end}`,
    );
  },
  /**
   *  发票申请
   */
  applyInvoice(data) {
    return request.post("/finance/account/invoice/apply", data);
  },
  /**
   *  交易订单列表
   */
  getOrderList(data) {
    return request.get("/finance/account/order/list", { params: data });
  },
  /**
   *  订单详情接口
   */
  getOrderListdetail(id) {
    return request.get(`/finance/account/order/${id}`);
  },
  /**
   *  发票申请列表
   */
  getInvoiceList(data) {
    return request.get("/finance/account/invoice/list", { params: data });
  },
  /**
   *  发票下载
   */
  downloadInvoice(applicationId, applyId, code) {
    return request.get(
      `/finance/account/invoice/download?application_id=${applicationId}&apply_id=${applyId}&code=${code}`,
    );
  },
  /**
   *  操作记录
   */
  optLogList: (data) =>
    request.get("/report/operate/record?operates=112001&operates=112002", {
      params: data,
    }),
};
