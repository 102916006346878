import fetch from "@/utils/fetch";

// 获取签约家族列表
export function getFamilys(data) {
  return fetch({
    url: "/Admin/family/getLeader",
    method: "post",
    data,
  });
}

// 签约家族-检索
export function signFamily(data) {
  return fetch({
    url: "/Admin/family/signFamily",
    method: "post",
    data,
  });
}

// 签约/完善家族
export function addFamily(data) {
  return fetch({
    url: "/Admin/family/addFamily",
    method: "post",
    data,
  });
}

// 签约家族-转让
export function updateFamily(data) {
  return fetch({
    url: "/Admin/family/assignFamily",
    method: "post",
    data,
  });
}

// 签约家族-合作
export function setCooperation(data) {
  return fetch({
    url: "/Admin/family/setCooperation",
    method: "post",
    data,
  });
}

// 签约家族-锁定
export function setLock(data) {
  return fetch({
    url: "/Admin/family/setLock",
    method: "post",
    data,
  });
}

// 成员管理-查询成员
export function getMembers(data) {
  return fetch({
    url: "/Admin/family/getMember",
    method: "post",
    data,
  });
}

// 成员管理-录入成员
export function addMember(data) {
  return fetch({
    url: "/Admin/family/addMember",
    method: "post",
    data,
  });
}

// 成员管理-录出成员
export function removeMember(data) {
  return fetch({
    url: "/Admin/family/removeMember",
    method: "post",
    data,
  });
}

// 成员管理-成员详情
export function getMemberInfo(data) {
  return fetch({
    url: "/Admin/family/getInfo",
    method: "post",
    data,
  });
}

// 成员管理-检索成员
export function getMemberInfoByHeyId(data) {
  return fetch({
    url: "/Admin/family/signMember",
    method: "post",
    data,
  });
}
