import request from "@/utils/request";

export default {
  /**
   * @description 获取主播流水记录
   * @param {*} params
   * @returns
   */
  getStarWaterInfo: (params) => request.get("/star/getstarwater", { params }),
};
