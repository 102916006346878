import Layout from "@/layout";

export default [
  {
    path: "/diamond",
    component: Layout,
    redirect: "/diamond/index",
    name: "diamond",
    meta: {
      title: "金币流水",
      icon: "component",
      permissions: ["diamond/index"],
      position: 5,
    },
    children: [
      {
        path: "index",
        component: () =>
          import(/* webpackChunkName: 'activity' */ "@/views/diamond/index"),
        name: "DiamondRecord",
        meta: { title: "金币流水", permissions: ["diamond/index"] },
      },
    ],
  },
];
