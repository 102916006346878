import Layout from "@/layout";

export default [
  {
    path: "/interact",
    component: Layout,
    redirect: "/interact/index",
    name: "interact",
    meta: {
      title: "动态管理",
      icon: "case",
      permissions: ["star_manage"],
      position: 16,
    },
    children: [
      {
        path: "index",
        component: () => import("@/views/interact/index"),
        name: "",
        meta: {
          title: "动态管理",
          permissions: ["star_manage"],
        },
      },
      {
        path: "topic",
        component: () => import("@/views/interact/topic"),
        name: "",
        meta: {
          title: "话题管理",
          permissions: ["star_manage"],
        },
      },
      {
        path: "reply",
        component: () => import("@/views/interact/reply"),
        name: "",
        meta: {
          title: "评论管理",
          permissions: ["star_manage"],
        },
      },
      {
        path: "report",
        component: () => import("@/views/interact/report"),
        name: "",
        meta: {
          title: "举报管理",
          permissions: ["star_manage"],
        },
      },
    ],
  },
];
