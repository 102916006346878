import request from "@/utils/request";

export default {
  /*
   * 权限登录
   * */
  listPremissions: () => request.get("/user/menu"),

  /**
   * @description 根据角色获取权限点
   * @param {number} id
   */
  listPremissionsByRole: (id) => request.get(`/user/permission/${id}`),

  /**
   * @description 保存权限到角色
   * @param {Object} data
   */
  savePremissiosForRole: (data) => request.post("/user/permission", data),

  /**
   * @description 新增角色
   * @param {Object} data
   */
  createUserRole: (data) => request.post("/user/role", data),

  /**
   * @description 删除角色
   * @param {Object} data
   */
  deleteUserRole: (val) => request.delete("/user/role", { data: val }),
};
