import Layout from "@/layout";

export default [
  {
    path: "/financial",
    component: Layout,
    redirect: "/financial/orderinfo",
    name: "financial",
    icon: "money",
    meta: {
      title: "财务管理",
      icon: "money",
      permissions: ["order_manage"],
      position: 15,
    },
    children: [
      {
        path: "orderinfo",
        component: () => import("@/views/financial/orderinfo"),
        name: "financialOrderinfo",
        meta: {
          title: "充值记录",
          permissions: ["order_manage"],
        },
      },
      {
        path: "pickup",
        component: () => import("@/views/financial/pickup"),
        name: "financialPickup",
        meta: {
          title: "提现申请",
          permissions: ["order_manage"],
        },
      },
      {
        path: "pickuplist",
        component: () => import("@/views/appuser/pickuplist"),
        name: "appuserPickuplist",
        meta: {
          title: "用户提现",
          permissions: ["order_manage"],
        },
      },
      {
        path: "usermoneyrecord",
        component: () => import("@/views/appuser/usermoneyrecord"),
        name: "appuserUsermoneyrecord",
        meta: {
          title: "用户消费记录",
          permissions: ["order_manage"],
        },
      },
      {
        path: "Roomgiftrank",
        component: () => import("@/views/userimage/Roomgiftrank"),
        name: "userimageRoomgiftrank",
        meta: {
          title: "房间消费统计",
          permissions: ["order_manage"],
        },
      },
    ],
  },
];
