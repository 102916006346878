import axios from "axios";
import store from "@/store/index";
import { Message } from "element-ui";
import { Settings } from "@/config";
import { PARTY_MANAGE_TOKEN } from "@/const";
import { EventBus } from "../framework/utils";
import { AppEventTypeEnum } from "../framework/enums";

let BASE_URL = Settings[APP_ENV].requestUrl;

const service = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
  headers: {
    "Cache-Control": "no-cache",
  },
  timeout: 10000,
});

/**
 * @description 添加请求头 token
 * 请求拦截
 * */
service.interceptors.request.use((config) => {
  const token = localStorage.getItem(PARTY_MANAGE_TOKEN) || "";
  // console.log("cookie:", token);
  config.headers.Authorization = `${token}`;
  document.cookie = token;
  return config;
});
/*
 * 返回拦截
 * */
const bizCodes = [10001];
service.interceptors.response.use(
  (response) => {
    const res = response.data;
    if (res.code !== 0 && bizCodes.indexOf(res.code) < 0) {
      Message({
        showClose: true,
        message: res.msg || "Error",
        type: "error",
        duration: 5 * 1000,
      });

      if (res.code === 5000000) {
        // ...需要重新登陆--成功location.reload()
      }
      return Promise.reject(new Error(res.msg || "Error"));
    }
    if (res.code === 10001) {
      window.alert("系统不存在该用户，请先添加, 点击确认返回登陆。");
      EventBus.$emit(AppEventTypeEnum.LOGIN_OUT);
    }
    return res;
  },
  (error) => {
    const { status, statusText } = error.response || {};
    if (status === -1 && statusText === "Unauthorized") {
      // eslint-disable-next-line no-undef
      store.dispatch("user/resetToken").then(() => {
        window.location.reload();
      });
    }
    if (status === 400) {
      Message({
        showClose: true,
        message: "参数错误",
        type: "error",
        duration: 5 * 1000,
      });
    } else if (status === 401) {
      store.dispatch("user/resetToken").then(() => {
        window.location.reload();
        Message({
          showClose: true,
          message: "401错误",
          type: "error",
          duration: 30 * 1000,
        });
      });
    } else {
      Message({
        showClose: true,
        message: "服务器异常",
        type: "error",
        duration: 5 * 1000,
      });
    }

    return Promise.reject(error);
  },
);
export default service;
