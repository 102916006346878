import Layout from "@/layout";

export default [
  {
    path: "/numbermanage",
    component: Layout,
    redirect: "/numbermanage/index",
    name: "numbermanage",
    meta: {
      title: "靓号管理",
      icon: "component",
      permissions: ["numbermanage/index"],
    },
    children: [
      {
        path: "index",
        component: () =>
          import(
            /* webpackChunkName: 'activity' */ "@/views/numbermanage/index"
          ),
        name: "numbermanage",
        meta: { title: "靓号管理", permissions: ["numbermanage/index"] },
      },
    ],
  },
];
