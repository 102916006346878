import axios from "axios";
import { Message } from "element-ui";
import store from "@/store";
import { PARTY_MANAGE_TOKEN } from "@/const";
import { Settings } from "@/config";

let BASE_URL = Settings[APP_ENV].requestUrl;

// 创建axios实例
const service = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
  headers: {
    "Cache-Control": "no-cache",
  },
  timeout: 10000,
});

// request拦截器
service.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem(PARTY_MANAGE_TOKEN) || "";
    config.headers.Authorization = `${token}`;
    document.cookie = token;

    // 检查请求数据的类型，如果是 JSON 类型，则转换为 URL 编码的格式
    if (
      config.data instanceof Object &&
      !(config.data instanceof Blob) &&
      !(config.data instanceof FormData)
    ) {
      const params = new URLSearchParams();
      for (const key in config.data) {
        params.append(key, config.data[key]);
      }
      config.data = params.toString();
      config.headers["Content-Type"] = "application/x-www-form-urlencoded";
    }

    return config;
  },
  (error) => {
    // Do something with request error
    Promise.reject(error);
  },
);

// respone拦截器
service.interceptors.response.use(
  // response => response,
  (response) => {
    /**
     * 下面的注释为通过response自定义code来标示请求状态，当code返回如下情况为权限有问题，登出并返回到登录页
     * 如通过xmlhttprequest 状态码标识 逻辑可写在下面error中
     */
    if (response.data instanceof Blob) {
      return response;
    }
    const res = response.data;
    if (res.code == -1) {
      store.dispatch("FedLogOut").then(() => {
        location.reload();
      });
    } else if (res.code !== 0) {
      Message({
        message: res.msg,
        type: "error",
        duration: 3 * 1000,
      });
      return Promise.reject("error");
    } else {
      return response.data;
    }
  },
  (error) => {
    Message({
      message: error.message,
      type: "error",
      duration: 5 * 1000,
    });
    return Promise.reject(error);
  },
);

export default service;
