import Layout from "@/layout";

export default [
  {
    path: "/heistar",
    component: Layout,
    redirect: "/heistar/approve",
    name: "heistar",
    meta: {
      title: "嘿星管理",
      icon: "star",
      permissions: ["star_manage"],
      position: 14,
    },
    children: [
      {
        path: "approve",
        component: () => import("@/views/heistar/approve"),
        name: "heistarApprove",
        meta: {
          title: "嘿星认证",
          permissions: ["star_manage"],
        },
      },
      {
        path: "fansiconverify",
        component: () => import("@/views/heistar/fansiconverify"),
        name: "heistarFansiconverify",
        meta: {
          title: "粉丝徽章审核",
          permissions: ["star_manage"],
        },
      },
      {
        path: "wage",
        component: () => import("@/views/heistar/wage"),
        name: "heistarWage",
        meta: {
          title: "嘿星工资结算",
          permissions: ["star_manage"],
        },
      },
      {
        path: "append",
        component: () => import("@/views/heistar/append"),
        name: "heistarAppend",
        meta: {
          title: "嘿星加钻",
          permissions: ["star_manage"],
        },
      },
      {
        path: "queue",
        component: () => import("@/views/heistar/queue"),
        name: "heistarQueue",
        meta: {
          title: "嘿嘿助手消息",
          permissions: ["star_manage"],
        },
      },
      {
        path: "payment",
        component: () => import("@/views/heistar/payment"),
        name: "heistarPayment",
        meta: {
          title: "打款审核",
          permissions: ["star_manage"],
        },
      },
    ],
  },
];
