import Layout from "@/layout";

export default [
  {
    path: "/npaycheck",
    component: Layout,
    redirect: "/npaycheck/pay-list",
    name: "npaycheck",
    meta: {
      title: "打款审核",
      icon: "pay",
      noCache: false,
      permissions: ["pay_check"],
      position: 9,
    },
    children: [
      {
        path: "pay-list",
        component: () => import("@/views/npaycheck/pay-list"),
        name: "payList",
        meta: {
          title: "打款审核",
          noCache: false,
          permissions: ["pay_check"],
          isParent: true,
        },
      },
    ],
  },
];
