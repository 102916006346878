import request from "@/utils/request";

export default {
  /**
   * @description 获取主播流水记录
   * @param {*} params
   * @returns
   */
  getPayCheckList: (params) =>
    request.get("/haiwai/pay/getpaychecklist", { params }),

  payCheckPayed: (params) => request.get("/haiwai/pay/checkpayed", { params }),
  payCheckReject: (params) =>
    request.get("/haiwai/pay/checkreject", { params }),
};
