/**
 * @description 任务
 */
import { Obj } from "../framework/utils";
import request from "@/utils/request";

export default {
  /**
   * @description 获取条件列表
   * @param {*} data
   * @returns
   */
  conditionList(data) {
    return request.get("/task/condition", { params: data });
  },

  /**
   * @description 添加条件
   * @param {*} data
   * @returns
   */
  addOrUpdateCondition(data) {
    return request.post("/task/condition", data);
  },

  /**
   * @description 任务curd
   */
  ...Obj.generateCURD("/task", request),

  /**
   * @description 任务详情
   * @param {*} id
   * @returns
   */
  detail(id) {
    return request.get(`/task/detail/${id}`);
  },
};
