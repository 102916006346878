import request from "@/utils/request";

export default {
  /*
   * 用户登录
   * */
  doLogin: async (data) => await request.post("/user/login", data),

  /*
   * 用户退出
   * */
  goLogout: async () => await request.post("/user/logout"),

  /**
   * @description 操作日志
   * @returns
   */
  optLogList: async (data) =>
    await request.get("/report/operate/record", { params: data }),

  /**
   * @description 获取用户权限
   * @returns
   */
  getUserPremissions: async () => await request.get("/user/mine"),
};
